var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({class:{
        'sw-input-number': true,
        'sw-input-number_inverted': _vm.inverted,
        'sw-input-number_large': _vm.size === _vm.SIZES.LARGE,
        'sw-input-number_small': _vm.size === _vm.SIZES.SMALL,
        'sw-input-number_roundings_none': _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.NONE,
        'sw-input-number_roundings_small': _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.SMALL,
        'sw-input-number_roundings_large': _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.LARGE,
        'sw-input-number_disabled': _vm.disabled,
    },style:(_vm.inverted
        ? {
            '--sw-color-main-500': 'rgba(255, 255, 255, 1)',
            '--sw-color-main-600': 'rgba(255, 255, 255, 0.8)',
            '--sw-color-main-700': 'rgba(255, 255, 255, 0.6)',
            '--sw-color-main-300': 'rgba(255, 255, 255, 0.4)',
            '--sw-color-main-200': 'rgba(255, 255, 255, 0.2)',
            '--sw-color-main-000': 'rgba(255, 255, 255, 0)'
        }
        : {})},_vm.$listeners),[_c('ClientOnly',[_c('UiInputNumber',{attrs:{"value":_vm.value,"max":_vm.max,"min":_vm.min,"step":_vm.step,"disabled":_vm.disabled},on:{"changeInputNumber":function (v) { return _vm.$emit('changeInputNumber', Math.floor(v)); }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }