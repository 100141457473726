import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0071f3ac = () => interopDefault(import('../website/pages/404.vue' /* webpackChunkName: "pages/404" */))
const _a4897548 = () => interopDefault(import('../website/pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _67152660 = () => interopDefault(import('../website/pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _4ae22282 = () => interopDefault(import('../website/pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _05ffdf03 = () => interopDefault(import('../website/pages/index.html.vue' /* webpackChunkName: "pages/index.html" */))
const _2f77a07c = () => interopDefault(import('../website/pages/order.vue' /* webpackChunkName: "pages/order" */))
const _69195a38 = () => interopDefault(import('../website/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _72fd2dca = () => interopDefault(import('../website/pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _1c13f343 = () => interopDefault(import('../website/pages/catalog/details/index.vue' /* webpackChunkName: "pages/catalog/details/index" */))
const _1e3d08c6 = () => interopDefault(import('../website/pages/user/orders.vue' /* webpackChunkName: "pages/user/orders" */))
const _500e8235 = () => interopDefault(import('../website/pages/user/restore-password/index.vue' /* webpackChunkName: "pages/user/restore-password/index" */))
const _1a5d51fb = () => interopDefault(import('../website/pages/catalog/details/_slug.vue' /* webpackChunkName: "pages/catalog/details/_slug" */))
const _37895c27 = () => interopDefault(import('../website/pages/user/restore-password/_token.vue' /* webpackChunkName: "pages/user/restore-password/_token" */))
const _73c7add8 = () => interopDefault(import('../website/pages/catalog/_product_group_slug.vue' /* webpackChunkName: "pages/catalog/_product_group_slug" */))
const _610d2174 = () => interopDefault(import('../website/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _28fe8653 = () => interopDefault(import('../website/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _0071f3ac,
    name: "404"
  }, {
    path: "/cart",
    component: _a4897548,
    name: "cart"
  }, {
    path: "/catalog",
    component: _67152660,
    name: "catalog"
  }, {
    path: "/checkout",
    component: _4ae22282,
    name: "checkout"
  }, {
    path: "/index.html",
    component: _05ffdf03,
    name: "index.html"
  }, {
    path: "/order",
    component: _2f77a07c,
    name: "order"
  }, {
    path: "/search",
    component: _69195a38,
    name: "search"
  }, {
    path: "/user",
    component: _72fd2dca,
    name: "user"
  }, {
    path: "/catalog/details",
    component: _1c13f343,
    name: "catalog-details"
  }, {
    path: "/user/orders",
    component: _1e3d08c6,
    name: "user-orders"
  }, {
    path: "/user/restore-password",
    component: _500e8235,
    name: "user-restore-password"
  }, {
    path: "/catalog/details/:slug",
    component: _1a5d51fb,
    name: "catalog-details-slug"
  }, {
    path: "/user/restore-password/:token?",
    component: _37895c27,
    name: "user-restore-password-token"
  }, {
    path: "/catalog/:product_group_slug",
    component: _73c7add8,
    name: "catalog-product_group_slug"
  }, {
    path: "/",
    component: _610d2174,
    name: "index"
  }, {
    path: "/*",
    component: _28fe8653,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
